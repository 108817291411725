import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const chatAccess = () => {
  if (!store.getters.buildingConfigValue.teacherParentChat) return "/404";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/chat",
        name: "chat",
        component: () => import("@/views/chat/index.vue"),
        beforeEnter: chatAccess,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/common/notifications/index.vue"),
      },
      {
        path: "/paiement",
        name: "paiement",
        component: () => import("@/views/Paiement/index.vue"),
      },

      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/index.vue"),
      },
      {
        path: "/avertissement",
        name: "avertissement",
        component: () => import("@/views/Avertissement/index.vue"),
      },
      {
        path: "/attendance",
        name: "attendance",
        component: () => import("@/views/attendance/index.vue"),
      },
      {
        path: "/cantine",
        name: "cantine",
        component: () => import("@/views/Cantine/index.vue"),
      },
      /*   {
           path: "/kutubee",
           name: "kutubee",
           component: () => import("@/views/Kutubee/index.vue"),
         },*/

      {
        path: "/emplois",
        name: "emplois",
        component: () => import("@/views/emplois/index.vue"),
      },

      {
        path: "/bulletin",
        name: "bulletin",
        component: () => import("@/views/bulletinGeneral/index.vue"),
        props: { type: "MP" },
      },
      {
        path: "/bulletinPilote",
        name: "bulletinPilote",
        component: () => import("@/views/bulletinGeneral/index.vue"),
        props: { type: "MT" },
      },
      {
        path: "/bulletinSpecific",
        name: "bulletinSpecific",
        component: () => import("@/views/bulletinGeneral/index.vue"),
        props: { type: "MS" },
      },
      {
        path: "/bulletin/annuelle",
        name: "bulletinAnnuelle",
        component: () => import("@/views/bulletin/annuelle/index.vue"),
      },
      {
        path: "/bulletinPilote/annuelle",
        name: "bulletinPiloteAnnuelle",
        component: () => import("@/views/bulletinSpecific/annuelle/index.vue"),
      },

      {
        path: "/bulletinSpecific/annuelle",
        name: "bulletinSpecificAnnuelle",
        component: () =>
          import("@/views/bulletinVerySpecific/annuelle/index.vue"),
      },
      {
        path: "/actualites",
        name: "actualites",
        component: () => import("@/views/actualites/index.vue"),
      },
      {
        path: "/devoirs",
        name: "devoirs",
        component: () => import("@/views/Homeworks/index.vue"),
      },
      {
        path: "/devoirs/:id/exercise/:hid",
        name: "exercise",
        component: () => import("@/views/Homeworks/components/Exercise.vue"),
      },
      {
        path: "/cours",
        name: "cours",
        component: () => import("@/views/Courses/index.vue"),
      },
      {
        path: "/courses/:id/course/:cid",
        name: "courses",
        component: () => import("@/views/Courses/components/Course.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/calendar/index.vue"),
      },
      {
        path: "/resource-deleted",
        name: "deleted-resource",
        component: () => import("@/views/common/DeletedResource.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-forgot",
        name: "password-forgot",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/PasswordForgot.vue"
          ),
      },
      {
        path: "/password-reset/:token",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/payment-success/:dbName",
    name: "payment-success",
    component: () => import("@/views/crafted/onlinePayment/success.vue"),
  },
  {
    path: "/payment-failure/:dbName",
    name: "payment-failure",
    component: () => import("@/views/crafted/onlinePayment/failure.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
/* eslint-disable */
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (
    !["sign-in", "password-reset", "password-forgot", "payment-failure", "payment-success"].includes(String(to.name))
  )
    store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
